<template>
  <div class="comprehensive-table-container">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="queryForm"
          :inline="true"
          label-width="100px"
          :model="queryForm"
          @submit.native.prevent
        >
          <div class="cont-form">
            <div>
              <el-form-item prop="jobNo">
                <!-- <el-input
              clearable
              v-model="queryForm.jobNo"
              placeholder="请输入"
            /> -->
                <el-input
                  v-model="queryForm.jobNo"
                  placeholder="运单号"
                  :rows="3"
                  style="width: 200px"
                  type="textarea"
                />
              </el-form-item>
            </div>
            <div>
              <el-form-item label="柜号" prop="cabinetNo">
                <el-input
                  v-model="queryForm.cabinetNo"
                  clearable
                  placeholder="请输入"
                  style="width: 180px"
                />
              </el-form-item>
              <el-form-item
                class="my-form-item"
                label="是否混箱"
                prop="isMixed"
              >
                <el-select
                  v-model="queryForm.isMixed"
                  clearable
                  placeholder="请选择类型"
                  style="width: 180px"
                >
                  <el-option
                    v-for="item in yseOrNo"
                    :key="item"
                    :label="item"
                    :value="item"
                  />
                </el-select>
              </el-form-item>
              <el-form-item
                class="my-form-item"
                label="报关方式"
                prop="customsType"
              >
                <el-select
                  v-model="queryForm.customsType"
                  clearable
                  placeholder="请选择类型"
                  style="width: 180px"
                >
                  <el-option
                    v-for="item in customsType"
                    :key="item"
                    :label="item"
                    :value="item"
                  />
                </el-select>
              </el-form-item>
              <el-form-item
                class="my-form-item"
                label="清关方式"
                prop="taxType"
              >
                <el-select
                  v-model="queryForm.taxType"
                  clearable
                  placeholder="请选择类型"
                  style="width: 180px"
                >
                  <el-option
                    v-for="item in taxType"
                    :key="item"
                    :label="item"
                    :value="item"
                  />
                </el-select>
              </el-form-item>
            </div>
          </div>
          <el-form-item>
            <el-button
              icon="el-icon-search"
              native-type="submit"
              type="primary"
              @click="handleQuery"
            >
              查询
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button native-type="submit" @click="reset('queryForm')">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
      <vab-query-form-left-panel>
        <el-button
          :loading="loadingExPort"
          size="mini"
          type="success"
          @click="exPort"
        >
          底单导出
        </el-button>
        <el-button
          :loading="loadingExPortPri"
          size="mini"
          type="info"
          @click="exPortPri"
        >
          报关资料导出
        </el-button>
      </vab-query-form-left-panel>
    </vab-query-form>

    <el-table
      ref="tableSort"
      v-loading="listLoading"
      border
      :cell-style="{ padding: '2px' }"
      :data="list"
      element-loading-text="拼命加载中"
      :height="height"
      :row-class-name="rowStyle"
      :row-style="{ height: '30px' }"
      @row-click="handleRowClick"
      @selection-change="setSelectRows"
    >
      <el-table-column
        align="center"
        show-overflow-tooltip
        type="selection"
        width="55"
      />
      <el-table-column
        align="center"
        class-name="myCell"
        fixed="left"
        label="柜号"
        prop="cabinetNo"
        width="140"
      >
        <template slot-scope="scope">
          <div
            v-for="(item, index) in scope.row.list"
            :key="index"
            class="myCellDiv"
            style="text-align: center"
          >
            {{ item.cabinetNo }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        class-name="myCell"
        fixed="left"
        label="运单号"
        prop="jobNo"
        width="140"
      >
        <template slot-scope="scope">
          <div
            v-for="(item, index) in scope.row.list"
            :key="index"
            class="myCellDiv"
            style="text-align: center"
          >
            {{ item.jobNo }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        class-name="myCell"
        label="客户中文品名"
        prop="cusProductCnName"
        width="140"
      >
        <template slot-scope="scope">
          <div
            v-for="(item, index) in scope.row.list"
            :key="index"
            class="myCellDiv"
            style="text-align: center"
          >
            {{ item.cusProductCnName }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        class-name="myCell"
        label="客户英文品名"
        prop="cusProductEnName"
        width="140"
      >
        <template slot-scope="scope">
          <div
            v-for="(item, index) in scope.row.list"
            :key="index"
            class="myCellDiv"
            style="text-align: center"
          >
            {{ item.cusProductEnName }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        class-name="myCell"
        label="海关编码"
        prop="hsCode"
        width="120"
      >
        <template slot-scope="scope">
          <div
            v-for="(item, index) in scope.row.list"
            :key="index"
            class="myCellDiv"
            style="text-align: center"
          >
            {{ item.hsCode }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        class-name="myCell"
        label="申报中文品名"
        prop="reportCnName"
        width="140"
      >
        <template slot-scope="scope">
          <div
            v-for="(item, index) in scope.row.list"
            :key="index"
            class="myCellDiv"
            style="text-align: center"
          >
            {{ item.reportCnName }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        class-name="myCell"
        label="申报英文品名"
        prop="reportEnName"
        width="140"
      >
        <template slot-scope="scope">
          <div
            v-for="(item, index) in scope.row.list"
            :key="index"
            class="myCellDiv"
            style="text-align: center"
          >
            {{ item.reportEnName }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        class-name="myCell"
        label="中国海关编码"
        prop="hsCodeCn"
        width="120"
      >
        <template slot-scope="scope">
          <div
            v-for="(item, index) in scope.row.list"
            :key="index"
            class="myCellDiv"
            style="text-align: center"
          >
            {{ item.hsCodeCn }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        class-name="myCell"
        label="中国申报要素"
        prop="reportFactor"
        width="120"
      >
        <template slot-scope="scope">
          <div
            v-for="(item, index) in scope.row.list"
            :key="index"
            class="myCellDiv"
            style="text-align: center"
          >
            {{ item.reportFactor }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        class-name="myCell"
        label="中文材质"
        prop="materialCn"
        width="140"
      >
        <template slot-scope="scope">
          <div
            v-for="(item, index) in scope.row.list"
            :key="index"
            class="myCellDiv"
            style="text-align: center"
          >
            {{ item.materialCn }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        class-name="myCell"
        label="英文材质"
        prop="materialEn"
        width="140"
      >
        <template slot-scope="scope">
          <div
            v-for="(item, index) in scope.row.list"
            :key="index"
            class="myCellDiv"
            style="text-align: center"
          >
            {{ item.materialEn }}
          </div>
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        label="子单号"
        prop="childJobNo"
        show-overflow-tooltip
        width="240"
      />
      <el-table-column
        align="center"
        class-name="myCell"
        label="品牌"
        prop="brand"
        width="140"
      >
        <template slot-scope="scope">
          <div
            v-for="(item, index) in scope.row.list"
            :key="index"
            class="myCellDiv"
            style="text-align: center"
          >
            {{ item.brand }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        class-name="myCell"
        label="品牌类型"
        prop="brandType"
        width="140"
      >
        <template slot-scope="scope">
          <div
            v-for="(item, index) in scope.row.list"
            :key="index"
            class="myCellDiv"
            style="text-align: center"
          >
            {{ item.brandType }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        class-name="myCell"
        label="规格"
        prop="specs"
        width="140"
      >
        <template slot-scope="scope">
          <div
            v-for="(item, index) in scope.row.list"
            :key="index"
            class="myCellDiv"
            style="text-align: center"
          >
            {{ item.specs }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        class-name="myCell"
        label="用途"
        prop="uses"
        width="140"
      >
        <template slot-scope="scope">
          <div
            v-for="(item, index) in scope.row.list"
            :key="index"
            class="myCellDiv"
            style="text-align: center"
          >
            {{ item.uses }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        class-name="myCell"
        label="货物类型"
        prop="goodsType"
        width="120"
      >
        <template slot-scope="scope">
          <div
            v-for="(item, index) in scope.row.list"
            :key="index"
            class="myCellDiv"
            style="text-align: center"
          >
            {{ item.goodsType }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        class-name="myCell"
        label="单位"
        prop="unit"
        width="80"
      >
        <template slot-scope="scope">
          <div
            v-for="(item, index) in scope.row.list"
            :key="index"
            class="myCellDiv"
            style="text-align: center"
          >
            {{ item.unit }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="件数"
        prop="pcs"
        show-overflow-tooltip
        width="80"
      />
      <el-table-column
        align="center"
        class-name="myCell"
        label="净重"
        prop="netWeight"
        width="100"
      >
        <template slot-scope="scope">
          <div
            v-for="(item, index) in scope.row.list"
            :key="index"
            class="myCellDiv"
            style="text-align: center"
          >
            {{ item.netWeight }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        class-name="myCell"
        label="毛重"
        prop="grossWeight"
        width="100"
      >
        <!-- <template slot-scope="scope">
          <div
            v-for="(item, index) in scope.row.list"
            :key="index"
            style="text-align: center"
            class="myCellDiv"
          >
            {{ item.grossWeight }}
          </div>
        </template> -->
      </el-table-column>
      <el-table-column
        align="center"
        class-name="myCell"
        label="收货总净重"
        prop="receiveNetWeight"
        width="100"
      >
        <!-- <template slot-scope="scope">
          <div
            v-for="(item, index) in scope.row.list"
            :key="index"
            style="text-align: center"
            class="myCellDiv"
          >
            {{ item.receiveNetWeight }}
          </div>
        </template> -->
      </el-table-column>
      <el-table-column
        align="center"
        class-name="myCell"
        label="收货总实重"
        prop="receiveGrossWeight"
        width="100"
      >
        <!-- <template slot-scope="scope">
          <div
            v-for="(item, index) in scope.row.list"
            :key="index"
            style="text-align: center"
            class="myCellDiv"
          >
            {{ item.receiveGrossWeight }}
          </div>
        </template> -->
      </el-table-column>
      <el-table-column
        align="center"
        label="体积"
        prop="vol"
        show-overflow-tooltip
        width="80"
      />

      <el-table-column
        align="center"
        class-name="myCell"
        label="数量"
        prop="qty"
        width="80"
      >
        <template slot-scope="scope">
          <div
            v-for="(item, index) in scope.row.list"
            :key="index"
            class="myCellDiv"
            style="text-align: center"
          >
            {{ item.qty }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        class-name="myCell"
        label="单价"
        prop="price"
        width="80"
      >
        <template slot-scope="scope">
          <div
            v-for="(item, index) in scope.row.list"
            :key="index"
            class="myCellDiv"
            style="text-align: center"
          >
            {{ item.price }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        class-name="myCell"
        label="总价"
        prop="totalPrice"
        width="80"
      >
        <template slot-scope="scope">
          <div
            v-for="(item, index) in scope.row.list"
            :key="index"
            class="myCellDiv"
            style="text-align: center"
          >
            {{ item.totalPrice }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="长"
        prop="length"
        show-overflow-tooltip
        width="80"
      />
      <el-table-column
        align="center"
        label="宽"
        prop="width"
        show-overflow-tooltip
        width="80"
      />
      <el-table-column
        align="center"
        label="高"
        prop="height"
        show-overflow-tooltip
        width="80"
      />
      <el-table-column
        align="center"
        class-name="myCell"
        fixed="right"
        label="链接"
        prop="transactionUrl"
        show-overflow-tooltip
        width="140"
      >
        <template slot-scope="scope">
          <div
            v-for="(item, index) in scope.row.list"
            :key="index"
            class="myCellDiv"
            style="text-align: center"
          >
            <a :href="item.transactionUrl" target="_blank">
              {{ item.transactionUrl }}
            </a>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        class-name="myCell"
        label="报关方式"
        prop="customsType"
        width="80"
      >
        <template slot-scope="scope">
          <div
            v-for="(item, index) in scope.row.list"
            :key="index"
            class="myCellDiv"
            style="text-align: center"
          >
            {{ item.customsType }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        class-name="myCell"
        label="清关方式"
        prop="taxType"
        width="80"
      >
        <template slot-scope="scope">
          <div
            v-for="(item, index) in scope.row.list"
            :key="index"
            class="myCellDiv"
            style="text-align: center"
          >
            {{ item.taxType }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        class-name="myCell"
        label="创建人"
        prop="createBy"
        width="100"
      >
        <template slot-scope="scope">
          <div
            v-for="(item, index) in scope.row.list"
            :key="index"
            class="myCellDiv"
            style="text-align: center"
          >
            {{ item.createBy }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        class-name="myCell"
        label="创建时间"
        prop="createTime"
        width="160"
      >
        <template slot-scope="scope">
          <div
            v-for="(item, index) in scope.row.list"
            :key="index"
            class="myCellDiv"
            style="text-align: center"
          >
            {{ item.createTime }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        class-name="myCell"
        fixed="right"
        label="是否混装"
        prop="isMixed"
        width="80"
      >
        <template slot-scope="scope">
          <div
            v-for="(item, index) in scope.row.list"
            :key="index"
            class="myCellDiv"
            style="text-align: center"
          >
            <span>
              <el-tag type="success">{{ item.isMixed }}</el-tag>
            </span>
          </div>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <div class="cont">
      全部合计：
      <span>订单毛重：{{ fomr.totalGrossWeight }}</span>
      <span>订单净重：{{ fomr.totalNetWeight }}</span>
      <span>收货毛重：{{ fomr.totalReceiveGrossWeight }}</span>
      <span>收货净重：{{ fomr.totalReceiveNetWeight }}</span>
      <span>件数：{{ fomr.totalPcs }}</span>
      <span>总数量：{{ fomr.totalQty }}</span>
      <span>金额：{{ fomr.totalPrice }}</span>
      <span>体积：{{ fomr.totalVol }}</span>
    </div>
    <div class="cont">
      选中数据合计：
      <span>订单毛重：{{ selectForm.grossWeight }}</span>
      <span>订单净重：{{ selectForm.netWeight }}</span>
      <span>收货毛重：{{ selectForm.receiveGrossWeight }}</span>
      <span>收货净重：{{ selectForm.receiveNetWeight }}</span>
      <span>件数：{{ selectForm.pcs }}</span>
      <span>总数量：{{ selectForm.qty }}</span>
      <span>金额：{{ selectForm.totalPrice }}</span>
      <span>体积：{{ selectForm.vol }}</span>
    </div>
    <el-pagination
      background
      :current-page="queryForm.pageNum"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :page-sizes="[100, 200, 300, 500, 1000]"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
  import { exportDraftCopy, exportProfile, pageDraftCopy } from '@/api/cmsApi'

  export default {
    name: 'DraftCopy',
    components: {},
    data() {
      return {
        customsType: ['自备', '买单'],
        taxType: ['自税', '包税'],
        list: [],
        fold: true,
        loadingExPort: false,
        loadingExPortPri: false,
        listLoading: false,
        dialogFormVisible: false,
        loadingDemo: false,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        importType: '',
        fileListOne: [],
        yseOrNo: ['是', '否'],
        file: [],
        height: this.$baseTableHeight(3) + 50,
        queryForm: {
          cabinetNo: '',
          isMixed: '',
          customsType: '',
          taxType: '',
          jobNo: '',
          pageNum: 1,
          pageSize: 100,
          idList: [],
        },
        //全部数据合计
        fomr: {
          totalNetWeight: 0,
          totalGrossWeight: 0,
          totalReceiveNetWeight: 0,
          totalReceiveGrossWeight: 0,
          totalPcs: 0,
          totalQty: 0,
          totalPrice: 0,
          totalVol: 0,
        },
        //选中数据合计
        selectForm: {
          receiveNetWeight: 0,
          receiveGrossWeight: 0,
          grossWeight: 0,
          netWeight: 0,
          pcs: 0,
          qty: '',
          totalPrice: 0,
          vol: 0,
        },
        pickerOptions: {
          disabledDate(v) {
            return v.getTime() < new Date().getTime() - 86400000
          },
        },
      }
    },

    created() {
      this.queryList()
    },
    methods: {
      async exPort() {
        try {
          this.loadingExPort = true
          const res = await exportDraftCopy(this.queryForm)
          this.loadingExPort = false
          this.jump(res.data)
        } catch (error) {
          this.loadingExPort = false
          this.$message.error('导出失败!')
        }
      },
      async exPortPri() {
        try {
          this.loadingExPortPri = true
          const res = await exportProfile(this.queryForm)
          this.jump(res.data)
          this.loadingExPortPri = false
        } catch (error) {
          this.loadingExPortPri = false
          this.$message.error('导出失败!')
        }
      },
      //重置
      reset(queryForm) {
        this.$refs[queryForm].resetFields()
        this.queryForm.pageNum = 1
        this.queryList()
      },
      handleSizeChange(val) {
        this.queryForm.pageNum = 1
        this.queryForm.pageSize = val
        this.queryList()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNum = val
        this.queryList()
      },
      setSelectRows(val) {
        let than = this
        // console.log(val)
        var list = []
        // if (val.length > 0) {
        //   console.log(2222);
        //   than.selectForm.grossWeight = val.reduce((pre, totalPcs) => {
        //     console.log(pre);
        //     console.log(totalPcs);
        //     // return pre + cur;
        //   });
        // } else {
        //   return (this.moneylisttotal = 0);
        // }
        let num = 2
        var dataList1 = []
        var dataList2 = []
        var dataList3 = []
        var dataList4 = []
        var dataList5 = []
        var dataList6 = []
        var dataList7 = []
        var dataList8 = []
        this.$refs.tableSort.selection.map((item) => {
          // item.list.forEach(function (itel) {
          console.log(Number(item.vol))
          dataList1.push(Number(item.grossWeight))
          dataList2.push(Number(item.netWeight))
          dataList3.push(Number(item.pcs))
          dataList4.push(Number(item.qty))
          dataList5.push(Number(item.totalPrice))
          dataList6.push(Number(item.vol))
          dataList7.push(Number(item.receiveNetWeight))
          dataList8.push(Number(item.receiveGrossWeight))
          // })
        })
        console.log(dataList3)
        console.log(dataList6)
        if (dataList1.length > 0) {
          than.selectForm.grossWeight = dataList1.reduce((pre, cur) => {
            return (parseFloat(pre) + parseFloat(cur)).toFixed(num)
          })
        } else {
          than.selectForm.grossWeight = 0
        }

        if (dataList2.length > 0) {
          than.selectForm.netWeight = dataList2.reduce((pre, cur) => {
            return (parseFloat(pre) + parseFloat(cur)).toFixed(num)
          })
        } else {
          than.selectForm.netWeight = 0
        }

        if (dataList3.length > 0) {
          than.selectForm.pcs = dataList3.reduce((pre, cur) => {
            return pre + cur
          })
        } else {
          than.selectForm.pcs = 0
        }

        if (dataList4.length > 0) {
          than.selectForm.qty = dataList4.reduce((pre, cur) => {
            return pre + cur
          })
        } else {
          than.selectForm.qty = 0
        }

        if (dataList5.length > 0) {
          than.selectForm.totalPrice = dataList5.reduce((pre, cur) => {
            return (parseFloat(pre) + parseFloat(cur)).toFixed(num)
          })
        } else {
          than.selectForm.totalPrice = 0
        }
        console.log(dataList6.length)
        if (dataList6.length > 0) {
          than.selectForm.vol = dataList6.reduce((pre, cur) => {
            return (parseFloat(pre) + parseFloat(cur)).toFixed(num)
          })
        } else {
          than.selectForm.vol = 0
          console.log(222)
        }

        if (dataList7.length > 0) {
          than.selectForm.receiveNetWeight = dataList7.reduce((pre, cur) => {
            return (parseFloat(pre) + parseFloat(cur)).toFixed(num)
          })
        } else {
          than.selectForm.receiveNetWeight = 0
        }

        if (dataList8.length > 0) {
          than.selectForm.receiveGrossWeight = dataList8.reduce((pre, cur) => {
            return (parseFloat(pre) + parseFloat(cur)).toFixed(num)
          })
        } else {
          than.selectForm.receiveGrossWeight = 0
        }

        // than.selectForm.grossWeight+item.grossWeight
        // than.selectForm.netWeight+item.netWeight
        // than.selectForm.pcs=than.selectForm.pcs+item.pcs
        // than.selectForm.qty+item.qty
        // than.selectForm.totalPrice+item.totalPrice
        // than.selectForm.vol+item.vol
        val.forEach(function (item, index) {
          item.idList.forEach(function (item, index) {
            list.push(item)
          })
        })
        this.queryForm.idList = list
      },
      rowStyle({ row }) {
        var arr = this.queryForm.idList
        for (let i = 0; i < arr.length; i++) {
          for (let k = 0; k < row.idList.length; k++) {
            if (row.idList[k] === arr[i]) {
              return 'rowStyle'
            }
          }
        }
      },
      handleRowClick(row, column) {
        if (column && column.label == '操作') {
          return
        }
        this.$refs.tableSort.toggleRowSelection(row)
      },
      handleQuery() {
        this.queryForm.pageNum = 1
        this.queryList()
      },
      //查询
      async queryList() {
        this.listLoading = true
        const res = await pageDraftCopy(this.queryForm)
        this.fomr.totalNetWeight = res.data.totalNetWeight
        this.fomr.totalGrossWeight = res.data.totalGrossWeight
        this.fomr.totalReceiveNetWeight = res.data.totalReceiveNetWeight
        this.fomr.totalReceiveGrossWeight = res.data.totalReceiveGrossWeight
        this.fomr.totalPcs = res.data.totalPcs
        this.fomr.totalQty = res.data.totalQty
        this.fomr.totalPrice = res.data.totalPrice
        this.fomr.totalVol = res.data.totalVol
        // console.log(res.data.pageList)
        // var list = res.data.pageList.map((item, index, array) => item.list)
        // var pageList = []
        // // console.log(list)
        // list.forEach(function (item, index) {
        //   item.forEach(function (item, index) {
        //     pageList.push(item)
        //   })
        // })
        // console.log(pageList)
        // this.pageList = pageList
        this.total = res.data.totalSize
        this.list = res.data.pageList
        this.listLoading = false

        // this.listLoading = true
        // const res = await pageDraftCopy(this.queryForm)
        // this.total = res.data.totalSize
        // this.list = res.data.pageList
        // this.listLoading = false
      },

      handleFold() {
        this.fold = !this.fold
      },
      jump(data) {
        this.$router.push({
          name: 'taskManage',
          params: {
            taskNo: data,
          },
        })
      },

      handleAdd() {},
      handleChangeOne(file, fileList) {
        if (fileList.length > 0) {
          this.fileListOne = [fileList[fileList.length - 1]]
        }
      },
      handleRemoveOne(file, fileList) {
        this.queryForm.appFile = null
        for (let i = 0; i < this.fileListOne.length; i++) {
          if (this.fileListOne[i].uid == file.uid) {
            this.fileListOne.splice(i, 1)
            break
          }
        }
      },
      handleAvatarSuccessImg(params) {
        this.file.push(params.file)
        this.bt()
      },
      async bt() {
        this.loadingDemo = true
        for (let i = 0; i < this.file.length; i++) {
          this.queryForm.appFile = this.file[i]
        }
        let form = new FormData()
        form.append('file', this.queryForm.appFile)
        try {
          if (this.importType == 1) {
            const res = await importBasicFileForInsert(form)
          } else {
            const res = await importBasicFileForUpdate(form)
          }
          this.clone()
        } catch (error) {
          this.clone()
        }
      },
      clone() {
        this.loadingDemo = false
        this.dialogFormVisible = false
        this.close()
        this.file = []
        this.$refs.upload.clearFiles()
        this.queryList()
      },
      //弹出框隐藏回调
      close() {
        this.$refs.upload.clearFiles()
        this.importType = ''
      },
    },
  }
</script>
<style lang="scss" scoped>
  .comprehensive-table-container {
    position: relative;
  }

  ::v-deep .left-panel {
    margin: 0px !important;
  }

  .posit {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
  }

  .cont {
    margin-top: 20px;

    span {
      margin-right: 20px;
    }
  }

  ::v-deep {
    .el-table {
      .el-table__body-wrapper::-webkit-scrollbar {
        width: 12px;
        height: 12px;
      }

      .el-table__body-wrapper::-webkit-scrollbar-thnmb {
        background-color: #ccc !important;
        border-radius: 5px;
      }

      // 此段可写可不写 用处：可使固定列的高度不盖住滚动条
      // /deep/.el-table__fiexd,
      // .el-table__fixed-right{
      //  height:calc(100% - 12px)!important;
      //}
      // 关键代码，解决错位问题 思路就是表格固定列显示滚动条后把表格顶上来
      .el-table-fixed .el-table__body {
        padding-bottom: 12px;
      }
    }

    .vab-query-form[data-v-64063760] .top-panel {
      margin: 0px;
    }

    .el-form-item {
      margin-bottom: 0px;
    }

    .left-panel {
      margin: 0px !important;
    }

    .left-panel {
      margin: 0px !important;
    }

    .el-table__body-wrapper.is-scrolling-right ~ .el-table__fixed-right {
      height: auto !important;
      bottom: 10px !important;

      &::before {
        background-color: transparent;
      }
    }

    .el-table__body-wrapper.is-scrolling-left ~ .el-table__fixed {
      height: auto !important;
      bottom: 10px !important;

      &::before {
        background-color: transparent;
      }
    }

    .el-dialog__body {
      text-align: center;
    }

    .rowStyle {
      background-color: #e8f7fd !important;
      color: #1890ff;
    }
  }

  // .my-form-item {
  // padding-left: 10px!important;
  // }
  .cont-form {
    display: flex;
  }
</style>
