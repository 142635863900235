<template>
  <div class="comprehensive-table-container">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="queryForm"
          :inline="true"
          label-width="100px"
          :model="queryForm"
          @submit.native.prevent
        >
          <div class="cont-form">
            <div>
              <el-form-item prop="jobNo">
                <!-- <el-input
              clearable
              v-model="queryForm.jobNo"
              placeholder="请输入"
            /> -->
                <el-input
                  v-model="queryForm.jobNo"
                  placeholder="运单号"
                  :rows="3"
                  style="width: 200px"
                  type="textarea"
                />
              </el-form-item>
            </div>
            <div>
              <el-form-item
                class="my-form-item"
                label="是否混箱"
                prop="isMixed"
              >
                <el-select
                  v-model="queryForm.isMixed"
                  clearable
                  placeholder="请选择类型"
                  style="width: 180px"
                >
                  <el-option
                    v-for="item in yseOrNo"
                    :key="item"
                    :label="item"
                    :value="item"
                  />
                </el-select>
              </el-form-item>
              <el-form-item
                class="my-form-item"
                label="报关方式"
                prop="customsType"
              >
                <el-select
                  v-model="queryForm.customsType"
                  clearable
                  placeholder="请选择类型"
                  style="width: 180px"
                >
                  <el-option
                    v-for="item in customsType"
                    :key="item"
                    :label="item"
                    :value="item"
                  />
                </el-select>
              </el-form-item>
              <el-form-item
                class="my-form-item"
                label="清关方式"
                prop="taxType"
              >
                <el-select
                  v-model="queryForm.taxType"
                  clearable
                  placeholder="请选择类型"
                  style="width: 180px"
                >
                  <el-option
                    v-for="item in taxType"
                    :key="item"
                    :label="item"
                    :value="item"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="柜号" prop="cabinetNo">
                <el-input
                  v-model="queryForm.cabinetNo"
                  clearable
                  placeholder="请输入"
                  style="width: 180px"
                />
              </el-form-item>
            </div>
          </div>
          <el-form-item>
            <el-button
              icon="el-icon-search"
              native-type="submit"
              type="primary"
              @click="handleQuery"
            >
              查询
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button native-type="submit" @click="reset('queryForm')">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
      <vab-query-form-left-panel>
        <el-button
          :loading="loadingExPort"
          size="mini"
          type="success"
          @click="exPort"
        >
          底单导出
        </el-button>
        <el-button
          :loading="loadingExPortPri"
          size="mini"
          type="info"
          @click="exPortPri"
        >
          报关资料导出
        </el-button>
      </vab-query-form-left-panel>
    </vab-query-form>

    <!-- <vxe-table
      ref="tableSort"
      v-loading="listLoading"
      border
      show-overflow
      show-header-overflow
      show-footer-overflow
      show-footer
      :data="list"
      :row-style="{ height: '30px' }"
      :cell-style="{ padding: '2px' }"
      element-loading-text="拼命加载中"
      @selection-change="setSelectRows"
      checkbox-config="{ checkField: 'checked', trigger: 'row' }"
      :height="height"
      :scroll-x=" {
        gt: 10,
        oSize:  10
        }"
      :span-method="objectSpanMethod"
    > -->
    <vxe-table
      border
      ref="xTable"
      :data="list"
      height="300"
      highlight-current-row
      :export-config="{}"
      highlight-hover-row
      :loading="listLoading"
      resizable
      :checkbox-config="{ reserve: true }"
      show-header-overflow
      :scroll-x="{
        gt: 10,
        oSize: 10,
      }"
      show-overflow
      :span-method="objectSpanMethod"
      @checkbox-all="checkboxAll"
      @sort-change="sortChange"
    >
      <vxe-column align="center" fixed="left" type="checkbox" width="55" />
      <vxe-column
        align="center"
        field="cabinetNo"
        fixed="left"
        title="柜号"
        width="140"
      />
      <vxe-column
        align="center"
        field="jobNo"
        fixed="left"
        title="运单号"
        width="140"
      />
      <vxe-column
        align="center"
        field="cusProductCnName"
        title="客户中文品名"
        width="140"
      />
      <vxe-column
        align="center"
        field="cusProductEnName"
        title="客户英文品名"
        width="140"
      />
      <vxe-column align="center" field="hsCode" title="海关编码" width="120" />
      <vxe-column
        align="center"
        field="reportCnName"
        title="申报中文品名"
        width="140"
      />
      <vxe-column
        align="center"
        field="reportEnName"
        title="申报英文品名"
        width="140"
      />
      <vxe-column
        align="center"
        field="hsCodeCn"
        title="中国海关编码"
        width="120"
      />
      <vxe-column
        align="center"
        field="reportFactor"
        title="中国申报要素"
        width="120"
      />
      <vxe-column
        align="center"
        field="materialCn"
        title="中文材质"
        width="140"
      />
      <vxe-column
        align="center"
        field="materialEn"
        title="英文材质"
        width="140"
      />

      <vxe-column
        align="center"
        field="childJobNo"
        show-overflow-tooltip
        title="子单号"
        width="240"
      />
      <vxe-column align="center" field="brand" title="品牌" width="140" />
      <vxe-column
        align="center"
        field="brandType"
        title="品牌类型"
        width="140"
      />
      <vxe-column align="center" field="specs" title="规格" width="140" />
      <vxe-column align="center" field="uses" title="用途" width="140" />
      <vxe-column
        align="center"
        field="goodsType"
        title="货物类型"
        width="120"
      />
      <vxe-column align="center" field="unit" title="单位" width="80" />
      <vxe-column
        align="center"
        field="pcs"
        show-overflow-tooltip
        title="件数"
        width="80"
      />
      <vxe-column align="center" field="netWeight" title="净重" width="100" />
      <vxe-column align="center" field="grossWeight" title="毛重" width="100">
        <!-- <template slot-scope="scope">
          <div
            v-for="(item, index) in scope.row.list"
            :key="index"
            style="text-align: center"
            class="myCellDiv"
          >
            {{ item.grossWeight }}
          </div>
        </template> -->
      </vxe-column>
      <vxe-column
        align="center"
        field="receiveNetWeight"
        title="收货总净重"
        width="100"
      >
        <!-- <template slot-scope="scope">
          <div
            v-for="(item, index) in scope.row.list"
            :key="index"
            style="text-align: center"
            class="myCellDiv"
          >
            {{ item.receiveNetWeight }}
          </div>
        </template> -->
      </vxe-column>
      <vxe-column
        align="center"
        field="receiveGrossWeight"
        title="收货总实重"
        width="100"
      >
        <!-- <template slot-scope="scope">
          <div
            v-for="(item, index) in scope.row.list"
            :key="index"
            style="text-align: center"
            class="myCellDiv"
          >
            {{ item.receiveGrossWeight }}
          </div>
        </template> -->
      </vxe-column>
      <vxe-column
        align="center"
        field="vol"
        show-overflow-tooltip
        title="体积"
        width="80"
      />

      <vxe-column align="center" field="qty" title="数量" width="80" />
      <vxe-column align="center" field="price" title="单价" width="80" />
      <vxe-column align="center" field="totalPrice" title="总价" width="80" />
      <vxe-column
        align="center"
        field="length"
        show-overflow-tooltip
        title="长"
        width="80"
      />
      <vxe-column
        align="center"
        field="width"
        show-overflow-tooltip
        title="宽"
        width="80"
      />
      <vxe-column
        align="center"
        field="height"
        show-overflow-tooltip
        title="高"
        width="80"
      />
      <!-- <vxe-column
        align="center"
        field="transactionUrl"
        title="链接"

        width="140"
        fixed="right"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <div
            v-for="(item, index) in scope.row.list"
            :key="index"
            style="text-align: center"
            class="myCellDiv"
          >
            <a :href="item.transactionUrl" target="_blank">{{ item.transactionUrl }}</a>
          </div>
        </template>
      </vxe-column> -->

      <vxe-column
        align="center"
        field="customsType"
        title="报关方式"
        width="80"
      />
      <vxe-column
        align="center"
        field="taxType"
        fixed="right"
        title="清关方式"
        width="80"
      />
      <vxe-column align="center" field="createBy" title="创建人" width="100" />
      <vxe-column
        align="center"
        field="createTime"
        title="创建时间"
        width="160"
      />
      <vxe-column
        align="center"
        field="isMixed"
        fixed="right"
        title="是否混装"
        width="80"
      />
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </vxe-table>
    <div class="cont">
      全部合计：
      <span>订单毛重：{{ fomr.totalGrossWeight }}</span>
      <span>订单净重：{{ fomr.totalNetWeight }}</span>
      <span>收货毛重：{{ fomr.totalReceiveGrossWeight }}</span>
      <span>收货净重：{{ fomr.totalReceiveNetWeight }}</span>
      <span>件数：{{ fomr.totalPcs }}</span>
      <span>总数量：{{ fomr.totalQty }}</span>
      <span>金额：{{ fomr.totalPrice }}</span>
      <span>体积：{{ fomr.totalVol }}</span>
    </div>
    <div class="cont">
      选中数据合计：
      <span>订单毛重：{{ selectForm.grossWeight }}</span>
      <span>订单净重：{{ selectForm.netWeight }}</span>
      <span>收货毛重：{{ selectForm.receiveGrossWeight }}</span>
      <span>收货净重：{{ selectForm.receiveNetWeight }}</span>
      <span>件数：{{ selectForm.pcs }}</span>
      <span>总数量：{{ selectForm.qty }}</span>
      <span>金额：{{ selectForm.totalPrice }}</span>
      <span>体积：{{ selectForm.vol }}</span>
    </div>
    <el-pagination
      background
      :current-page="queryForm.pageNum"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :page-sizes="[100, 200, 300, 500, 1000]"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script>
  import {
    exportDraftCopy,
    exportProfile,
    pageDraftCopyTwo,
  } from '@/api/cmsApi'
  export default {
    name: 'DraftCopy',
    components: {},
    data() {
      return {
        customsType: ['自备', '买单'],
        taxType: ['自税', '包税'],
        list: [],
        fold: true,
        loadingExPort: false,
        loadingExPortPri: false,
        listLoading: false,
        dialogFormVisible: false,
        loadingDemo: false,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        importType: '',
        fileListOne: [],
        yseOrNo: ['是', '否'],
        file: [],
        height: this.$baseTableHeight(1),
        queryForm: {
          cabinetNo: '',
          isMixed: '',
          customsType: '',
          taxType: '',
          jobNo: '',
          pageNum: 1,
          pageSize: 100,
          idList: [],
        },
        //全部数据合计
        fomr: {
          totalNetWeight: 0,
          totalGrossWeight: 0,
          totalReceiveNetWeight: 0,
          totalReceiveGrossWeight: 0,
          totalPcs: 0,
          totalQty: 0,
          totalPrice: 0,
          totalVol: 0,
        },
        //选中数据合计
        selectForm: {
          receiveNetWeight: 0,
          receiveGrossWeight: 0,
          grossWeight: 0,
          netWeight: 0,
          pcs: 0,
          qty: '',
          totalPrice: 0,
          vol: 0,
        },
        pickerOptions: {
          disabledDate(v) {
            return v.getTime() < new Date().getTime() - 86400000
          },
        },
      }
    },

    created() {
      this.queryList()
    },
    activated() {
      this.queryList()
    },
    methods: {
      sortChange() {},
      checkboxAll() {},
      objectSpanMethod({ row, column, rowIndex, columnIndex }) {
        //   console.log('++++++++++++++++++++++++')
        //   console.log('[rowIndex]', rowIndex)
        //   console.log('[columnIndex]', columnIndex)
        //   console.log('[row]', row)
        //   console.log('[column]', column)
        //   console.log('////////////////////////')

        if (row['cellMerge']) {
          // 列名
          let columnName = column['property']
          if (row['cellMerge'][columnName]) {
            let obj = {
              rowspan: row['cellMerge'][columnName]['rowspan'],
              colspan: row['cellMerge'][columnName]['colspan'],
            }
            //   console.log(obj)
            return obj
          }
        }
      },
      async exPort() {
        try {
          this.loadingExPort = true
          const res = await exportDraftCopy(this.queryForm)
          this.loadingExPort = false
          this.jump(res.data)
        } catch (error) {
          this.loadingExPort = false
          this.$message.error('导出失败!')
        }
      },
      async exPortPri() {
        try {
          this.loadingExPortPri = true
          const res = await exportProfile(this.queryForm)
          this.jump(res.data)
          this.loadingExPortPri = false
        } catch (error) {
          this.loadingExPortPri = false
          this.$message.error('导出失败!')
        }
      },
      //重置
      reset(queryForm) {
        this.$refs[queryForm].resetFields()
        this.queryForm.pageNum = 1
        this.queryList()
      },
      handleSizeChange(val) {
        this.queryForm.pageNum = 1
        this.queryForm.pageSize = val
        this.queryList()
      },
      handleCurrentChange(val) {
        this.queryForm.pageNum = val
        this.queryList()
      },
      setSelectRows(val) {
        let than = this
        // console.log(val)
        var list = []
        // if (val.length > 0) {
        //   console.log(2222);
        //   than.selectForm.grossWeight = val.reduce((pre, totalPcs) => {
        //     console.log(pre);
        //     console.log(totalPcs);
        //     // return pre + cur;
        //   });
        // } else {
        //   return (this.moneylisttotal = 0);
        // }
        let num = 2
        var dataList1 = []
        var dataList2 = []
        var dataList3 = []
        var dataList4 = []
        var dataList5 = []
        var dataList6 = []
        var dataList7 = []
        var dataList8 = []
        this.$refs.tableSort.selection.map((item) => {
          // item.list.forEach(function (itel) {
          console.log(Number(item.vol))
          dataList1.push(Number(item.grossWeight))
          dataList2.push(Number(item.netWeight))
          dataList3.push(Number(item.pcs))
          dataList4.push(Number(item.qty))
          dataList5.push(Number(item.totalPrice))
          dataList6.push(Number(item.vol))
          dataList7.push(Number(item.receiveNetWeight))
          dataList8.push(Number(item.receiveGrossWeight))
          // })
        })
        console.log(dataList3)
        console.log(dataList6)
        if (dataList1.length > 0) {
          than.selectForm.grossWeight = dataList1.reduce((pre, cur) => {
            return (parseFloat(pre) + parseFloat(cur)).toFixed(num)
          })
        } else {
          than.selectForm.grossWeight = 0
        }

        if (dataList2.length > 0) {
          than.selectForm.netWeight = dataList2.reduce((pre, cur) => {
            return (parseFloat(pre) + parseFloat(cur)).toFixed(num)
          })
        } else {
          than.selectForm.netWeight = 0
        }

        if (dataList3.length > 0) {
          than.selectForm.pcs = dataList3.reduce((pre, cur) => {
            return pre + cur
          })
        } else {
          than.selectForm.pcs = 0
        }

        if (dataList4.length > 0) {
          than.selectForm.qty = dataList4.reduce((pre, cur) => {
            return pre + cur
          })
        } else {
          than.selectForm.qty = 0
        }

        if (dataList5.length > 0) {
          than.selectForm.totalPrice = dataList5.reduce((pre, cur) => {
            return (parseFloat(pre) + parseFloat(cur)).toFixed(num)
          })
        } else {
          than.selectForm.totalPrice = 0
        }
        console.log(dataList6.length)
        if (dataList6.length > 0) {
          than.selectForm.vol = dataList6.reduce((pre, cur) => {
            return (parseFloat(pre) + parseFloat(cur)).toFixed(num)
          })
        } else {
          than.selectForm.vol = 0
          console.log(222)
        }

        if (dataList7.length > 0) {
          than.selectForm.receiveNetWeight = dataList7.reduce((pre, cur) => {
            return (parseFloat(pre) + parseFloat(cur)).toFixed(num)
          })
        } else {
          than.selectForm.receiveNetWeight = 0
        }

        if (dataList8.length > 0) {
          than.selectForm.receiveGrossWeight = dataList8.reduce((pre, cur) => {
            return (parseFloat(pre) + parseFloat(cur)).toFixed(num)
          })
        } else {
          than.selectForm.receiveGrossWeight = 0
        }

        // than.selectForm.grossWeight+item.grossWeight
        // than.selectForm.netWeight+item.netWeight
        // than.selectForm.pcs=than.selectForm.pcs+item.pcs
        // than.selectForm.qty+item.qty
        // than.selectForm.totalPrice+item.totalPrice
        // than.selectForm.vol+item.vol
        val.forEach(function (item, index) {
          item.idList.forEach(function (item, index) {
            list.push(item)
          })
        })
        this.queryForm.idList = list
      },
      rowStyle({ row }) {
        var arr = this.queryForm.idList
        for (let i = 0; i < arr.length; i++) {
          for (let k = 0; k < row.idList.length; k++) {
            if (row.idList[k] === arr[i]) {
              return 'rowStyle'
            }
          }
        }
      },
      handleRowClick(row, column) {
        if (column && column.label == '操作') {
          return
        }
        this.$refs.tableSort.toggleRowSelection(row)
      },
      handleQuery() {
        this.queryForm.pageNum = 1
        this.queryList()
      },
      //查询
      async queryList() {
        this.listLoading = true
        const res = await pageDraftCopyTwo(this.queryForm)
        this.fomr.totalNetWeight = res.data.totalNetWeight
        this.fomr.totalGrossWeight = res.data.totalGrossWeight
        this.fomr.totalReceiveNetWeight = res.data.totalReceiveNetWeight
        this.fomr.totalReceiveGrossWeight = res.data.totalReceiveGrossWeight
        this.fomr.totalPcs = res.data.totalPcs
        this.fomr.totalQty = res.data.totalQty
        this.fomr.totalPrice = res.data.totalPrice
        this.fomr.totalVol = res.data.totalVol
        // console.log(res.data.pageList)
        // var list = res.data.pageList.map((item, index, array) => item.list)
        // var pageList = []
        // // console.log(list)
        // list.forEach(function (item, index) {
        //   item.forEach(function (item, index) {
        //     pageList.push(item)
        //   })
        // })
        // console.log(pageList)
        // this.pageList = pageList
        this.total = res.data.totalSize
        this.list = res.data.pageList
        //   this.listLoading = false
        const xTable = this.$refs.xTable
        if (!xTable) return
        const startTime = Date.now()
        if (xTable) {
          this.listLoading = false
          this.list = Object.freeze(this.list)
          xTable.refreshScroll()
          console.log(xTable.getScroll())
          /* this.$refs.xTable.reloadData(data).then(() => {
            //this.$XModal.message({ content: `渲染 ${size} 行，用时 ${Date.now() - startTime}毫秒`, status: 'info' })
                this.loading = false
                xTable.refreshScroll();
            }) */

          const xGrid = this.$refs.xGrid
          // xGrid.reloadData(this.list)
        }
        // this.listLoading = true
        // const res = await pageDraftCopyTwo(this.queryForm)
        // this.total = res.data.totalSize
        // this.list = res.data.pageList
        // this.listLoading = false
      },

      handleFold() {
        this.fold = !this.fold
      },
      jump(data) {
        this.$router.push({
          name: 'taskManage',
          params: {
            taskNo: data,
          },
        })
      },

      handleAdd() {},
      handleChangeOne(file, fileList) {
        if (fileList.length > 0) {
          this.fileListOne = [fileList[fileList.length - 1]]
        }
      },
      handleRemoveOne(file, fileList) {
        this.queryForm.appFile = null
        for (let i = 0; i < this.fileListOne.length; i++) {
          if (this.fileListOne[i].uid == file.uid) {
            this.fileListOne.splice(i, 1)
            break
          }
        }
      },
      handleAvatarSuccessImg(params) {
        this.file.push(params.file)
        this.bt()
      },
      async bt() {
        this.loadingDemo = true
        for (let i = 0; i < this.file.length; i++) {
          this.queryForm.appFile = this.file[i]
        }
        let form = new FormData()
        form.append('file', this.queryForm.appFile)
        try {
          if (this.importType == 1) {
            const res = await importBasicFileForInsert(form)
          } else {
            const res = await importBasicFileForUpdate(form)
          }
          this.clone()
        } catch (error) {
          this.clone()
        }
      },
      clone() {
        this.loadingDemo = false
        this.dialogFormVisible = false
        this.close()
        this.file = []
        this.$refs.upload.clearFiles()
        this.queryList()
      },
      //弹出框隐藏回调
      close() {
        this.$refs.upload.clearFiles()
        this.importType = ''
      },
    },
  }
</script>
<style lang="scss" scoped>
  .comprehensive-table-container {
    position: relative;
  }
  ::v-deep .left-panel {
    margin: 0px !important;
  }
  .posit {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
  }
  .cont {
    margin-top: 20px;
    span {
      margin-right: 20px;
    }
  }
  // ::v-deep {
  //   .el-table {
  //     .el-table__body-wrapper::-webkit-scrollbar {
  //       width: 12px;
  //       height: 12px;
  //     }
  //     .el-table__body-wrapper::-webkit-scrollbar-thnmb {
  //       background-color: #ccc !important;
  //       border-radius: 5px;
  //     }
  //     // 此段可写可不写 用处：可使固定列的高度不盖住滚动条
  //     // /deep/.el-table__fiexd,
  //     // .el-table__fixed-right{
  //     //  height:calc(100% - 12px)!important;
  //     //}
  //     // 关键代码，解决错位问题 思路就是表格固定列显示滚动条后把表格顶上来
  //     .el-table-fixed .el-table__body {
  //       padding-bottom: 12px;
  //     }
  //   }

  //   .vab-query-form[data-v-64063760] .top-panel {
  //     margin: 0px;
  //   }
  //   .el-form-item {
  //     margin-bottom: 0px;
  //   }
  //   .left-panel {
  //     margin: 0px !important;
  //   }
  //   .left-panel {
  //     margin: 0px !important;
  //   }
  // //   .el-table__body-wrapper.is-scrolling-right ~ .el-table__fixed-right {
  // //     height: auto !important;
  // //     bottom: 10px !important;
  // //     &::before {
  // //       background-color: transparent;
  // //     }
  // //   }
  // //   .el-table__body-wrapper.is-scrolling-left ~ .el-table__fixed {
  // //     height: auto !important;
  // //     bottom: 10px !important;
  // //     &::before {
  // //       background-color: transparent;
  // //     }
  // //   }
  //   .el-dialog__body {
  //     text-align: center;
  //   }
  //   .rowStyle {
  //     background-color: #e8f7fd !important;
  //     color: #1890ff;
  //   }
  // }
  // .my-form-item {
  // padding-left: 10px!important;
  // }
  .cont-form {
    display: flex;
  }
</style>
